export const headers = {
    headerSolicitud: [
        {
            text: "N° SOLICITUD",
            align: "center",
            value: "numeroSolicitud",
            width: "5%",
            sortable: false,
        },
        {
            text: "ESTADO",
            align: "center",
            value: "estado",
            width: "5%",
            sortable: false,
        },
        {
            text: "FECHA SOLICITUD",
            align: "center",
            value: "fechaSolicitud",
            width: "5%",
            sortable: false,
        },
        {
            text: "N° INGRESO",
            align: "center",
            value: "numeroIngreso",
            width: "5%",
            sortable: false,
        },
        {
            text: "FECHA CANCELACIÓN",
            align: "center",
            value: "fechaCancelacion",
            width: "5%",
            sortable: false,
        },
        {
            text: "UBICACIÓN",
            align: "center",
            value: "ubicacionServicio",
            width: "18%",
            sortable: false,
        },
        {
            text: "UBICACIÓN PACIENTE",
            align: "center",
            value: "ubicacionPaciente",
            width: "19%",
            sortable: false,
        },
        {
            text: "TIPO DOCUMENTO",
            align: "center",
            value: "tipoIdentificacion",
            width: "5%",
            sortable: false,
        },
        {
            text: "N° DOCUMENTO",
            align: "center",
            value: "numeroIdentificacion",
            width: "10%",
            sortable: false,
        },
        {
            text: "NOMBRE",
            align: "center",
            value: "nombrePaciente",
            width: "15%",
            sortable: false,
        },
        {
            text: "CAMA",
            align: "center",
            value: "numeroCama",
            width: "5%",
            sortable: false,
        },
        {
            text: "DESPACHO",
            align: "center",
            value: "despacho",
            width: "3%",
            sortable: false,
        },
    ],
    headerSolicitudDetalle: [
        {
            text: "N° SOLICITUD",
            align: "center",
            value: "numeroSolicitud",
            width: "10%",
            sortable: false,
        },
        {
            text: "ESTADO",
            align: "center",
            value: "estado",
            width: "10%",
            sortable: false,
        },
        {
            text: "CÓDIGO GENÉRICO",
            align: "center",
            value: "codigoGenerico",
            width: "5%",
            sortable: false,
        },
        {
            text: "NOMBRE GENÉRICO",
            align: "center",
            value: "nombreGenerico",
            width: "30%",
            sortable: false,
        },
        {
            text: "CANTIDAD SOLICITADA",
            align: "center",
            value: "cantidadSolicitada",
            width: "1%",
            sortable: false,
        },
        {
            text: "CANTIDAD AUTORIZADA",
            align: "center",
            value: "cantidadAprobada",
            width: "1%",
            sortable: false,
        },
        {
            text: "CANTIDAD ENTREGADA",
            align: "center",
            value: "cantidadEntregada",
            width: "1%",
            sortable: false,
        },
        {
            text: "CANTIDAD CANCELADA",
            align: "center",
            value: "cantidadCancelada",
            width: "1%",
            sortable: false,
        },
        {
            text: "CANTIDAD PENDIENTE",
            align: "center",
            value: "cantidadPendiente",
            width: "1%",
            sortable: false,
        },
        {
            text: "CANTIDAD DEVUELTA",
            align: "center",
            value: "cantidadDevuelta",
            width: "1%",
            sortable: false,
        },
        {
            text: "MIPRES",
            align: "center",
            value: "mipres",
            width: "10%",
            sortable: false,
        },
        {
            text: "INFORMACIÓN ADICIONAL",
            align: "center",
            value: 'data-table-expand',
            width: "5%",
            sortable: false,
        },
        {
            text: "ENTREGAR CANTIDAD",
            align: "center",
            value: "agregarCantidad",
            width: "5%",
            sortable: false,
        },
        
    ],
    headerConsumo: [
        {
            text: "N° SOLICITUD",
            align: "center",
            value: "numeroSolicitud",
            width: "5%",
            sortable: false,
        },
        {
            text: "ESTADO",
            align: "center",
            value: "estado",
            width: "5%",
            sortable: false,
        },
        {
            text: "FECHA SOLICITUD",
            align: "center",
            value: "fechaSolicitud",
            width: "10%",
            sortable: false,
        },
        {
            text: "FECHA CANCELACIÓN",
            align: "center",
            value: "fechaCancelacion",
            width: "10%",
            sortable: false,
        },
        {
            text: "UBICACIÓN",
            align: "center",
            value: "ubicacionServicio",
            width: "15%",
            sortable: false,
        },
        {
            text: "DESPACHO",
            align: "center",
            value: "despacho",
            width: "3%",
            sortable: false,
        },
    ],
    headerConsumoDetalle: [
        {
            text: "ESTADO",
            align: "center",
            value: "estado",
            width: "10%",
            sortable: false,
        },
        {
            text: "CÓDIGO GENÉRICO",
            align: "center",
            value: "codigoGenerico",
            width: "10%",
            sortable: false,
        },
        {
            text: "NOMBRE GENÉRICO",
            align: "center",
            value: "nombreGenerico",
            width: "45%",
            sortable: false,
        },
        {
            text: "CANTIDAD SOLICITADA",
            align: "center",
            value: "cantidadSolicitada",
            width: "2%",
            sortable: false,
        },
        {
            text: "CANTIDAD ENTREGADA",
            align: "center",
            value: "cantidadEntregada",
            width: "2%",
            sortable: false,
        },
        {
            text: "CANTIDAD CANCELADA",
            align: "center",
            value: "cantidadCancelada",
            width: "2%",
            sortable: false,
        },
        {
            text: "CANTIDAD PENDIENTE",
            align: "center",
            value: "cantidadPendiente",
            width: "2%",
            sortable: false,
        },
        {
            text: "CANTIDAD DEVUELTA",
            align: "center",
            value: "cantidadDevuelta",
            width: "2%",
            sortable: false,
        },
        {
            text: "INFORMACIÓN ADICIONAL",
            align: "center",
            value: 'data-table-expand',
            width: "5%",
            sortable: false,
        },
        {
            text: "ENTREGAR CANTIDAD",
            align: "center",
            value: "agregarCantidad",
            width: "5%",
            sortable: false,
        }
        
    ],

    headerDevolucion: [
        {
            text: "N° SOLICITUD",
            align: "center",
            value: "numeroSolicitud",
            width: "5%",
            sortable: false,
        },
        {
            text: "TIPO SOLICITUD",
            align: "center",
            value: "tipoSolicitud",
            width: "5%",
            sortable: false,
        },
        {
            text: "ESTADO",
            align: "center",
            value: "estado",
            width: "5%",
            sortable: false,
        },
        {
            text: "FECHA SOLICITUD",
            align: "center",
            value: "fechaSolicitud",
            width: "10%",
            sortable: false,
        },
        {
            text: "FECHA CANCELACIÓN",
            align: "center",
            value: "fechaCancelacion",
            width: "10%",
            sortable: false,
        },
        {
            text: "UBICACIÓN",
            align: "center",
            value: "ubicacionServicio",
            width: "15%",
            sortable: false,
        },
        {
            text: "NOMBRE",
            align: "center",
            value: "nombrePaciente",
            width: "15%",
            sortable: false,
        },
        {
            text: "N° DOCUMENTO",
            align: "center",
            value: "numeroIdentificacion",
            width: "15%",
            sortable: false,
        },
        {
            text: "DEVOLUCIÓN",
            align: "center",
            value: "despacho",
            width: "3%",
            sortable: false,
        },
    ],

    headerDevolucionDetalle: [
        {
            text: "ESTADO",
            align: "center",
            value: "estado",
            width: "10%",
            sortable: false,
        },
        {
            text: "CÓDIGO COHAN",
            align: "center",
            value: "codigoProducto",
            width: "10%",
            sortable: false,
        },
        {
            text: "NOMBRE COHAN",
            align: "center",
            value: "nombreCohan",
            width: "35%",
            sortable: false,
        },
        {
            text: "LOTE",
            align: "center",
            value: "lote",
            width: "10%",
            sortable: false,
        },

        {
            text: "CANTIDAD ACEPTADA",
            align: "center",
            value: "cantidadAceptada",
            width: "2%",
            sortable: false,
        },
        {
            text: "CANTIDAD DEVUELTA",
            align: "center",
            value: "cantidadDevuelta",
            width: "2%",
            sortable: false,
        },
        {
            text: "FECHA VENCIMIENTO",
            align: "center",
            value: "fechaVencimiento",
            width: "2%",
            sortable: false,
        },
        {
            text: "RECIBIR DEVOLUCIÓN",
            align: "center",
            value: "agregarCantidad",
            width: "5%",
            sortable: false,
        }
        
    ],

    headerDevolucionConsumo: [
        {
            text: "N° SOLICITUD",
            align: "center",
            value: "numeroSolicitud",
            width: "5%",
            sortable: false,
        },
        {
            text: "TIPO SOLICITUD",
            align: "center",
            value: "tipoSolicitud",
            width: "5%",
            sortable: false,
        },
        {
            text: "ESTADO",
            align: "center",
            value: "estado",
            width: "5%",
            sortable: false,
        },
        {
            text: "FECHA SOLICITUD",
            align: "center",
            value: "fechaSolicitud",
            width: "10%",
            sortable: false,
        },
        {
            text: "FECHA CANCELACIÓN",
            align: "center",
            value: "fechaCancelacion",
            width: "10%",
            sortable: false,
        },
        {
            text: "UBICACIÓN",
            align: "center",
            value: "ubicacionServicio",
            width: "15%",
            sortable: false,
        },
        {
            text: "DEVOLUCIÓN",
            align: "center",
            value: "despacho",
            width: "3%",
            sortable: false,
        },
    ],
};