<template>
  <div>
    <form class="d-block">
      <v-container style="align-items: center; padding-bottom: 0; padding-top: 0;" class="d-flex m-0" fluid>
        <label class="pt-1 me-3 ms-1" for="">Seleccione el tipo de proceso:</label>
        <v-radio-group class="mt-0" row v-model="filtros.solicitud">
          <v-radio class="me-4 mt-2" label="Solicitudes de paciente" color="blue" value="S"></v-radio>
          <v-radio class="mt-2" label="Solicitudes de consumo" color="blue" value="C"></v-radio>
          <v-radio class="mt-2" label="Devoluciones" color="blue" value="D"></v-radio>
        </v-radio-group>
      </v-container>

      <v-container v-if="filtrosSolicitud" class="d-flex ms-1 me-1">

        <v-text-field style="width:25%;" v-model="numeroSolicitud" class="me-2" label="N° solicitud" outlined dense
          hide-details>
        </v-text-field>

        <v-autocomplete style="width:25%;" :items="tipoSolicitud" v-if="filtrosDevolucion" v-model="tipoSolicitudDevolucion" class="me-2" label="Tipo solicitud" outlined dense
          hide-details>
        </v-autocomplete>

        <v-autocomplete style="width:25%;" v-model="estado" :items="opcionesEstados" outlined dense label="Estado" class="me-2"
          hide-details>
        </v-autocomplete>

        <v-menu ref="menuFechas" v-model="menuFechas" :close-on-content-click="false" :return-value.sync="fechas"
          transition="scale-transition" offset-y min-width="auto">

          <template v-slot:activator="{ on, attrs }">

            <v-text-field style="width:25%;" class="me-2" outlined dense readonly v-model="dateRangeText" label="Fecha solicitud"
              hide-details v-bind="attrs" v-on="on"></v-text-field>

          </template>

          <v-date-picker locale="es-co" :first-day-of-week="1" range v-model="fechas" no-title scrollable>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="limpiarFechas()">
              Borrar
            </v-btn>

          </v-date-picker>
        </v-menu>

        <v-autocomplete style="width:25%;" v-model="informacionUbicacionFiltro" return-object :item-text="seleccionFiltroUbicacion" class="me-2" outlined dense
          label="Ubicación" :items="litaFiltroUbicaciones" hide-details>
        </v-autocomplete>
      </v-container>

      <v-container v-if="filtrosSolicitud" class="d-flex ms-1" style="padding-top: 0;">

        <v-text-field v-show="filtrosConsumo" style="width:25%;" v-model="numeroIngreso" class="me-2" outlined dense
          label="N° ingreso" hide-details>
        </v-text-field>
        
        <v-text-field v-show="filtrosConsumo" style="width:25%;" class="me-2" v-model="numeroDocumento" outlined dense
          label="N° documento" hide-details>
        </v-text-field>

        <v-text-field v-show="filtrosConsumo" style="width:25%;" class="me-2" v-model="nombrePaciente" outlined dense
          label="Nombre" hide-details>
        </v-text-field>

        <v-text-field v-show="filtrosConsumo" style="width:25%;" class="me-2" v-model="cama" outlined dense label="Cama"
          hide-details>
        </v-text-field>
      </v-container>
    </form>

    <v-data-table :headers="headersInformacionPrincipal" :items="informacionTablaPrincipal"
      :server-items-length="numeroRegistros" :page="page" :items-per-page="itemPorPage"
      :loading = tablaAnimacion
      @update:items-per-page="(i) => itemPorPage = i" 
      @update:page="(p) => page = p"
      :footer-props="footerProps" loading-text="Cargando..." class="elevation-1 tablaPrincipal ms-4 me-4" fixed-header>

      <template v-slot:[`item.despacho`]="{ item }">
        <v-btn @click="refrescarDetalles(item)" icon>
          <v-icon color="blue"> note_add </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-dialog  persistent max-width="95%" v-model="dialogDetalle">
      <v-card style="max-height: 77vh;">
        <v-card-title style="background-color: #0D47A1; color: white">
          {{tituloDetalle}}
        </v-card-title>

        <v-data-table :headers="headersInformacionDetalle" :items="informacionTablaDetalle"
          class="elevation-1 tablaDetalle" :footer-props="footerProps" fixed-header :loading=tablaAnimacion :single-expand=true
          :show-expand="activarExpand" item-key="idDetalleSolicitud"  @item-expanded="getHistorialDespachos" loading-text="Cargando...">
          <template v-slot:[`item.agregarCantidad`]="{ item }">
            <v-btn @click="informacionFilaDetalle(item)"
              :disabled = desactivarEntregas(item) icon>
              <v-icon color="blue">border_color</v-icon>
          </v-btn>
          </template>
          
          <template v-slot:[`expanded-item`]="{headers}">
            <td style="padding: 8px;" :colspan="headers.length">
              <tr>
                <td style="padding-right: 10px;"  v-for="item in resultadoHistorial" :key="item.numeroSolicitud">
                  <v-card style="padding-left: 5px;" elevation="3">
                    <div style="padding: 7px;">
                    <strong>Codigo Producto:</strong> <br> {{ item.codigoProducto}} <br>
                    <strong>Nombre Producto:</strong> <br>{{ item.nombreCohan}} <br>
                    <strong>Lote:</strong> <br>{{ item.lote }} <br>
                    <strong>Cantidad Despachada:</strong> <br> {{ item.cantidadDespachada }}<br>
                    <strong>Dosis: </strong> <br>{{ item.dosis }}<br>
                    <strong>Unidad de medida: </strong> <br> {{ item.unidadMedida }} <br>
                    <strong>Via: </strong> <br>{{ item.viaAdministracion }}<br>
                    <strong>Frecuencia: </strong> <br> {{ item.tiempoFrecuencia }} <br>
                    <strong>Duración: </strong> <br> {{ item.tiempoDuracion }} <br>
                    <strong>Unidad de tiempo: </strong> <br> {{ item.unidadTiempo }}<br>
                    <strong>Fecha Entrega: </strong> <br> {{ item.fechaCreacion }}<br>
                    <strong>Fecha Vencimiento: </strong> <br> <p :style="{ background: getColorFondoFechaVencimiento(item), color:getColorTextoFechaVencimiento(item)}">{{ item.fechaVencimiento }}</p> <br>
                  </div>
                </v-card>
                </td> 
              </tr>
            </td> 
          </template>
        </v-data-table>

        <v-card elevation="0" style="display: flex; justify-content: end; border-radius: 0;">
            <v-btn @click="validarCierreDetalle()" class="white--text" depressed text style="margin: 15px"
              color="red">Cerrar</v-btn>
            <v-btn @click="postPosponerEntregas()" class="white--text ms-0 ps-0" :disabled="activarBotonAplazar" depressed align-center text style="margin: 15px"
                    color="red">{{botonAplazar}}</v-btn>
            <v-btn @click="postCerrarEntrega()" class="white--text ms-0 ps-0" :disabled="activarBotonEntregas" depressed text style="margin: 15px"
              color="green">{{ botonRealizar }}</v-btn>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="50%" v-model="dialogEntrega">
      <form action="">
        <v-card>
          <v-card-title style="background-color: #0D47A1; color: white">
            Entregar cantidad ~ Unidades: {{this.cantidadSolicitadaDetalle}}
          </v-card-title>
          <div>
            <v-autocomplete outlined dense v-model="informacionAlternativas" return-object :item-text="seleccionNombreCohan" label="Código ~ Nombre producto"
              :items="respuestaAlternativas"
              class="ms-2 me-2 mt-3 mb-3" hide-details>
            </v-autocomplete>
            <v-autocomplete :filled = "inputUbicacionActivar" :disabled = "inputUbicacionActivar" outlined
             return-object :item-text="selectUbicacionMedicamento" :items="ubicacionesMedicamentos"
             dense label="Ubicación" v-model="selectUbicacion" class="ms-2 me-2 mt-2 mb-3" hide-details>
            </v-autocomplete>
            <v-autocomplete outlined dense v-model="informacionLotes" return-object :item-text="seleccionCantidades"
              :items="respuestaCantidades" :filled="selectLote" :disabled="selectLote" label="Lote ~ Cantidad herinco ~ Cantidad stone ~ fecha vencimiento ~ COD_AAA"
              class="ms-2 me-2 mt-2 mb-3" hide-details>
            </v-autocomplete>           
            <v-text-field v-model="codigoTripleA" v-if="codigoBodega == 5153" :filled = "inputTripleAactivar" :disabled = "inputTripleAactivar" outlined dense label="Codigo triple A" class="ms-2 me-2 mt-2 mb-3" hide-details>
            </v-text-field>
            <v-text-field type="number" required v-model="inputCantidad" :filled = "inputCantidadActivar" :disabled = "inputCantidadActivar"  outlined dense label="Cantidad a entregar" class="ms-2 me-2 mt-2 mb-3"
              hide-details>
            </v-text-field>
            <v-autocomplete v-model="informacionUbicacion" :items="respuestaUbicacion" return-object :item-text="seleccionUbicacion"
              outlined :filled = "inputUbicacionMotivo" :disabled = "inputUbicacionMotivo" dense label="Elija la ubicación del motivo" class="ms-2 me-2 mt-2 mb-3"
              hide-details>
            </v-autocomplete>
            <v-autocomplete v-model="informacionMotivo" :items="respuestaMotivo" return-object :item-text="seleccionMotivo"
              outlined :filled = "desactivarMotivo" :disabled = "desactivarMotivo" dense label="Elija el motivo" class="ms-2 me-2 mt-2 mb-3"
              hide-details>
            </v-autocomplete>
          </div>
          <v-card elevation="0" style="display: flex; justify-content: end; border-radius: 0;">
            <v-btn class="white--text" depressed @click="resetearVariables()"  text style="margin: 5px"
              color="red">cerrar</v-btn>
            <v-btn :disabled="desactivarBotonAgregar" :filled="desactivarBotonAgregar" @click="postAgregarProducto()" class="white--text" depressed text style="margin: 5px"
              color="green">Agregar</v-btn>
          </v-card>

        </v-card>
      </form>
    </v-dialog>

    <v-dialog persistent max-width="50%" v-model="dialogDevolucion">
      <form>
        <v-card>
          <v-card-title style="background-color: #0D47A1; color: white">Devolver cantidad ~ Unidades: {{this.itemSeleccionadoDetalle.cantidadDevuelta}} </v-card-title>
            <div>
              <v-text-field type="number" required v-model="inputCantidadDevuelta" outlined dense label="Cantidad a devolver" class="ms-2 me-2 mt-2 mb-3"
                    hide-details>
              </v-text-field>

              <v-autocomplete v-model="informacionUbicacion" :items="respuestaUbicacion" return-object :item-text="seleccionUbicacion"
                    outlined dense :filled="desactivarUbicacionDevolucion" :disabled="desactivarUbicacionDevolucion" label="Elija la ubicación del motivo" class="ms-2 me-2 mt-2 mb-3"
                    hide-details>
              </v-autocomplete>
              
              <v-autocomplete v-model="informacionMotivo" :items="respuestaMotivo" return-object :item-text="seleccionMotivo"
                    outlined dense :filled="desactivarMotivoDevolucion" :disabled="desactivarMotivoDevolucion" label="Elija el motivo" class="ms-2 me-2 mt-2 mb-3"
                    hide-details>
              </v-autocomplete>
            </div>

            <v-card elevation="0" style=" display: flex; justify-content: end; border-radius: 0;">
              <v-btn class="white--text" depressed @click="cerrarDialogdevolucion()"  text style="margin: 5px"
              color="red">Cerrar</v-btn>
              <v-btn :disabled="desactivarBotonAgregar" :filled="desactivarBotonAgregar" @click="postAgregarProducto()" class="white--text" depressed text style="margin: 5px"
              color="green">Agregar</v-btn>
            </v-card>
            
          </v-card>
      </form> 
    </v-dialog>
    <v-dialog persistent max-width="35%" v-model="dialogDescarga">
      <form>
        <v-card>
          <v-card-title style="background-color: #005296; color: white">Confirmación Descarga</v-card-title>
          <v-card-text style="padding: 1rem;" class="text-h6">¿Desea descargar el informe del despacho?</v-card-text>
          <v-card elevation="0" style=" display: flex; justify-content: end; border-radius: 0;">
              <v-btn class="white--text" depressed @click="dialogDescarga = false"  text style="margin: 5px"
              color="red">Cerrar</v-btn>
              <v-btn @click="descargarPdf()" class="white--text" depressed text style="margin: 5px"
              color="green">Aceptar</v-btn>
          </v-card>
        </v-card>
      </form>
    </v-dialog>
    <v-snackbar v-model="snackbarVisible" :color="colorSnackbar" multi-line top :timeout="timeSnackbar">
      <p v-html="mensajeInformativo"></p>   
      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" text v-bind="attrs" @click="snackbarVisible = false">CERRAR</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { defineComponent } from "@vue/composition-api";
import { headers } from "@/lib/headersMonitorOrdenamiento";
import moment from "moment";

const debounce = function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default defineComponent({
  data() {
    return {
      fechaActual: new Date(),
      dialogDetalle: false,
      dialogEntrega: false,
      dialogDevolucion:false,
      dialogDescarga: false,
      tablaAnimacion: false,
      snackbarVisible: false,
      activarExpand: true,
      activarBotonEntregas: true,
      activarBotonAplazar: true,
      botonAplazar:"",
      botonRealizar:"",
      mensajeInformativo:"",
      numeroSolicitud: "",
      fechas: [],
      numeroIngreso: "",
      estado: "",
      ubicacionServicio: "",
      numeroDocumento: "",
      nombrePaciente: "",
      nombrePacienteDetalle: "",
      cama: "",
      codigoBodega: "",
      nombreUsuario: "",
      idConsumo: "",
      idDevolucion: "",
      tituloDetalle:"",
      codigoTripleA: "",
      page: 1,
      itemPorPage: 10,
      totalPage: 1,
      numeroRegistros: 0,
      numeroIngresoDetalle: "",
      numeroIdentificacionDetalle:"",
      nombreServicioDetalle: "",
      numeroSolicitudDetalle: "",
      numeroSolicitudConsumo: "",
      cantidadSolicitadaDetalle:"",
      selectUbicacion: {},
      inputCantidad: "",
      inputCantidadDevuelta:"",
      ubicacionMotivo: "",
      numeroSolicitudAtendida:"",
      colorSnackbar:"",
      timeSnackbar:5000,
      selectLote: true,
      menuFechas: false,
      filtrosSolicitud: false,
      filtrosConsumo: true,
      filtrosDevolucion: false,
      inputUbicacionActivar: true,
      inputTripleAactivar: true,
      inputCantidadActivar: true,
      inputUbicacionMotivo: true,
      desactivarMotivo: true,
      desactivarUbicacionDevolucion: true,
      desactivarMotivoDevolucion: true,
      desactivarBotonAgregar: false,
      botonFilaDetalle: false,
      filtros: {
        solicitud: ""
      },
      itemSeleccionado: {},
      itemSeleccionadoDetalle: {},
      informacionTablaPrincipal: [],
      informacionTablaDetalle: [],
      litaFiltroUbicaciones:[],
      respuestaPreRemision: [],
      ubicacionesMedicamentos:[],
      respuestaCantidades: [],
      respuestaAgregarProducto: [],
      respuestaAlternativas: [],
      respuestaUbicacion: [],
      respuestaMotivo: [],
      respuestaCerrarEntrega:[],
      respuestaDetallesEstados:[],
      resultadoHistorial:[],
      respuestaCancelacion:[],
      respuestaValidarUsuario:[],
      informacionMotivo:{},
      informacionUbicacion:{},
      informacionLotes: {},
      informacionAlternativas: {},
      informacionUbicacionFiltro:{},
      rmtCadv:"",
      rmtCare: "",
      rmtUbic: "",
      idDespacho: "",
      idDevuelto:"",
      informacionCantidades: {},
      tipoSolicitudDevolucion: "",
      tipoSolicitud: [
        { text: "Paciente", value: "S" },
        { text: "Consumo", value: "C" },
      ],
      items: [
        { number: "5", numberValue: 5 },
        { number: "10", numberValue: 10 },
        { number: "25", numberValue: 25 },
        { number: "50", numberValue: 50 },
        { number: "100", numberValue: 100 },
      ],
      opcionesEstados: [
        { text: "Nuevo", value: "N" },
        { text: "Atendido", value: "A" },
        { text: "Entregado", value: "E" },
        { text: "Terminado", value: "T" },
        { text: "Cancelado", value: "C" },
        { text: "Facturado", value: "F" },
        { text: "Parcial", value: "P" },
        { text: "Todos", value: "-1" },
      ],
      footerProps: {
        showFirstLastPage: true,
        "show-current-page": true,
        'items-per-page-text': 'Ítems por página',
        'items-per-page-options': [5, 10, 20, 50,],
        'items-length': 100
      },
      headersInformacionPrincipal: [],
      headersInformacionDetalle: []
    };
  },
  created() {
    this.getUbicacionesFiltro();
    this.filtros.solicitud = 'S';
  },

  computed: {
    ...mapState(["enterprise"]),
    dateRangeText () {
      if(this.fechas == ""){
        return "";
      }
      return this.fechas.join(' ~ ');
    },

  },

  methods: {

    getHistorialDespachos({item}){
      if(this.filtros.solicitud == "S"){
        this.$http.get("msa-pharmaceutical-services/historialDespachosSolicitudes", {
          params:{
            numeroIngreso: this.numeroIngresoDetalle,
            numeroSolicitud: item.numeroSolicitud,
            codigoGenerico: item.codigoGenerico
          }
        }).then((result) => {
            this.resultadoHistorial = result.data.map((item) => {
                const { fechaCreacion, ...element } = item;
                return {
                  fechaCreacion: moment(fechaCreacion).format("YYYY/MM/DD"),
                  ...element,
                } 
              })
          })
        }
      if(this.filtros.solicitud == "C"){
        this.$http.get("msa-pharmaceutical-services/historialDespachosConsumos", {
          params:{
            codigoUbicacion: this.itemSeleccionado.codigoUbicacion,
            numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
            codigoGenerico: item.codigoGenerico
          }
        }).then((result) => {
            this.resultadoHistorial = result.data.map((item) => {
                const { fechaCreacion, ...element } = item;
                return {
                  fechaCreacion: moment(fechaCreacion).format("YYYY/MM/DD"),
                  ...element,
                }
              })
          }) 
        }
    },

    getColorFondoFechaVencimiento(item){
      const fechaActual = moment();
      let fechaVencimiento = moment(item.fechaVencimiento);
      if(fechaVencimiento.isBefore(fechaActual)){
        return 'red'
      }
    }, 
    getColorTextoFechaVencimiento(item){
      const fechaActual = moment();
      let fechaVencimiento = moment(item.fechaVencimiento);
      if(fechaVencimiento.isBefore(fechaActual)){
        return 'white'
      }
    }, 

    getUbicacionesFiltro(){
        this.$http.get("msa-pharmaceutical-services/ubicacionesFiltro").then((result) => {
          this.litaFiltroUbicaciones = result.data;
        })
    },

    limpiarFechas() {
      this.fechas = [];
      this.$refs.menuFechas.save(this.fechas);
      this.fechas.join(' ~ ');
      this.menuFechas = false;
      this.getTablaPrincipal();
    },

    desactivarEntregas(item){
      if(this.filtros.solicitud == "S" || this.filtros.solicitud == "C"){
        if(item.estado == 'Cancelado' || item.cantidadSolicitada == item.cantidadEntregada
        || item.cantidadSolicitada == (item.cantidadEntregada + item.cantidadCancelada) || 
        this.botonFilaDetalle == true){
          return true;
        }
        return false;
      }
      if(this.filtros.solicitud == "D"){
        if(item.estado == 'Cancelado' || item.cantidadAceptada == item.cantidadDevuelta
          || this.botonFilaDetalle == true){
          return true;
        }
        return false;
      }
    },

    getTablaPrincipal() {
      this.informacionTablaPrincipal = [];
      this.tablaAnimacion = true;
      moment.updateLocale('col', { invalidDate: "" });
      let fechaInicial = "";
      let fechaFinal = "";
      if(this.fechas[0] != null){
        if(moment(this.fechas[0]).isAfter(this.fechas[1])) {
          fechaInicial = this.fechas[1]+"T00:00:00-00:00";
          fechaFinal = this.fechas[0]+"T23:59:59-00:00";
        }else{
          fechaInicial = this.fechas[0]+"T00:00:00-00:00";
          fechaFinal = this.fechas[1]+"T23:59:59-00:00";
        }
      }
      if (this.filtros.solicitud == 'S' && this.numeroSolicitud >= 0 && this.numeroIngreso >= 0) {
        this.activarExpand = true;
        this.$http.get("msa-pharmaceutical-services/monitorSolicitudes", {
          params: {
            page: this.page - 1,
            size: this.itemPorPage,
            numeroSolicitud: this.numeroSolicitud,
            estado: this.estado,
            fechaInicial: fechaInicial,
            fechaFinal: fechaFinal,
            codigoUbicacion: this.informacionUbicacionFiltro?.codigoUbicacion,
            numeroIngreso: this.numeroIngreso,
            ubicacionServicio: this.ubicacionServicio,
            numeroDocumento: this.numeroDocumento,
            nombre: this.nombrePaciente,
            cama: this.cama
          }
        }).then((result) => {
          console.log(result.data)
          this.numeroRegistros = result.data.numeroRegistros;
          this.totalPage = result.data.numeroPaginas;
          this.nombreUsuario = result.data.nombreUsuario;
          this.codigoBodega = result.data.codigoBodega;
          this.informacionTablaPrincipal = result.data.informacionSolicitudConsumo.map((item) => {
            const { fechaSolicitud, fechaCancelacion, ...element } = item;
            return {
              fechaSolicitud: moment(fechaSolicitud).format("YYYY/MM/DD"),
              fechaCancelacion: moment(fechaCancelacion).format("YYYY/MM/DD"),
              ...element,
            }
          });
            this.tablaAnimacion = false
        }).catch((error) => {
            console.log(error);
          });
      }

      if (this.filtros.solicitud == 'C' && this.numeroSolicitud >= 0 ) {
        this.activarExpand = true
        this.$http.get("msa-pharmaceutical-services/monitorConsumos", {
          params: {
            page: this.page - 1,
            size: this.itemPorPage,
            numeroSolicitud: this.numeroSolicitud,
            estado: this.estado,
            fechaInicial: fechaInicial,
            fechaFinal: fechaFinal,
            codigoUbicacion: this.informacionUbicacionFiltro?.codigoUbicacion,
          }
        }).then((result) => {
          this.numeroRegistros = result.data.numeroRegistros;
          this.nombreUsuario = result.data.nombreUsuario;
          this.codigoBodega = result.data.codigoBodega;
          this.totalPage = result.data.numeroPaginas;
          this.informacionTablaPrincipal = result.data.informacionSolicitudConsumo.map((item) => {
            const { fechaSolicitud, fechaCancelacion, ...element } = item;
              return {
                fechaSolicitud: moment(fechaSolicitud).format("YYYY/MM/DD"),
                fechaCancelacion: moment(fechaCancelacion).format("YYYY/MM/DD"),
                ...element,
              }
          });
          this.tablaAnimacion = false;
        }).catch((error) => {
          console.log(error);
        });
      }

      if (this.filtros.solicitud == 'D' && this.numeroSolicitud >= 0) {
        this.activarExpand = false;
        this.$http.get("msa-pharmaceutical-services/tablaPrincipalDevoluciones", {
          params: {
            page: this.page - 1,
            size: this.itemPorPage,
            numeroSolicitud: this.numeroSolicitud,
            tipoSolicitud: this?.tipoSolicitudDevolucion,
            estado: this.estado,
            fechaInicial: fechaInicial,
            fechaFinal: fechaFinal,
            codigoUbicacion: this.informacionUbicacionFiltro?.codigoUbicacion,
          }
        }).then((result) => {
          this.numeroRegistros = result.data.numeroRegistros;
          this.nombreUsuario = result.data.nombreUsuario;
          this.codigoBodega = result.data.codigoBodega;
          this.totalPage = result.data.numeroPaginas;
          this.informacionTablaPrincipal = result.data.informacionSolicitudConsumo.map((item) => {
            const { fechaSolicitud, fechaCancelacion, ...element } = item;
              return {
                fechaSolicitud: moment(fechaSolicitud).format("YYYY/MM/DD"),
                fechaCancelacion: moment(fechaCancelacion).format("YYYY/MM/DD"),
                ...element,
              }
          });
          this.tablaAnimacion = false;
        }).catch((error) => {
          console.log(error);
        });
      }
    },

    async refrescarDetalles(item){
      this.itemSeleccionado = item;
      this.idConsumo = item.idConsumo;
      this.idDevolucion = item.idDevolucion;
      this.numeroIngresoDetalle = item.numeroIngreso;
      this.nombrePacienteDetalle = item.nombrePaciente;
      this.numeroIdentificacionDetalle = item.numeroIdentificacion;
      this.numeroSolicitudDetalle = item.numeroSolicitud;
      this.numeroSolicitudConsumo = this.itemSeleccionado.numeroSolicitud;
      this.nombreServicioDetalle = item.ubicacionServicio;
      this.activarBotonEntregas = true;
      this.activarBotonAplazar = true;
        await this.GetSolicitudesIncompletas();
        this.ValidarEstados();
    },

    getTablaDetalles() {
      this.informacionTablaDetalle = [];
      this.tablaAnimacion = true;
      moment.updateLocale('col', { invalidDate: "" });
      if (this.filtros.solicitud == 'S') {
        this.tituloDetalle = "Detalle del paciente: " + this.nombrePacienteDetalle + "~ N° Documento: " + this.numeroIdentificacionDetalle + " ~ N° Ingreso: " + this.numeroIngresoDetalle;
        this.$http.get("msa-pharmaceutical-services/monitorSolicitudesDetalles", {
          params: {
            idSolicitud: this.itemSeleccionado.idSolicitud
          }
        }).then((result) => {
          this.informacionTablaDetalle = result.data.informacionDetalleSolicitudConsumo.map((item) => {
            const { fechaCreacion, ...element } = item;
            return {
              fechaCreacion: moment(fechaCreacion).format("YYYY/MM/DD"), ...element,
            };
          });
            this.tablaAnimacion = false;
        }).catch((error) => {
            console.log(error);
          });
        this.dialogDetalle = true;
      }

      if (this.filtros.solicitud == 'C') {
        this.tituloDetalle = "Detalle del consumo con número de solicitud " + this.numeroSolicitudConsumo;
        this.dialogDetalle = true;
        this.$http.get("msa-pharmaceutical-services/monitorConsumosDetalles", {
          params: {
            idConsumo: this.idConsumo,
          }
        }).then((result) => {
          this.informacionTablaDetalle = result.data.informacionDetalleSolicitudConsumo;
          this.tablaAnimacion = false;
        }).catch((error) => {
          console.log(error);
          })
        this.dialogDetalle = true;
      }

      if (this.filtros.solicitud == 'D') {
        let paciente;
        if(this.codigoBodega == 5103 || this.codigoBodega == 5123){
          if(this.itemSeleccionado.nombrePaciente == null){
            paciente = "";
          }else{
            paciente = this.itemSeleccionado.nombrePaciente; 
          }
          this.tituloDetalle = "Detalle de la devolución con número de solicitud: " + this.numeroSolicitudConsumo + " ~ Paciente: " + paciente;
        }
        else{
          this.tituloDetalle = "Detalle de la devolución con número de solicitud: " + this.numeroSolicitudConsumo;
        }
        this.dialogDetalle = true;
        this.$http.get("msa-pharmaceutical-services/tablaDevolucionesDetalles", {
          params: {
            idDevolucion: this.idDevolucion,
            codigoEmpresa: this.enterprise.code,
          }
        }).then((result) => {
          this.informacionTablaDetalle = result.data;
          this.tablaAnimacion = false;
        }).catch((error) => {
          console.log(error);
          })
        this.dialogDetalle = true;
      }
    },

    async informacionFilaDetalle(item) {
      this.botonFilaDetalle = true;
      this.desactivarBotonAgregar = false;
      await this.postValidarUsuarios();
      if(this.respuestaValidarUsuario.response == true){
        if(this.filtros.solicitud == "S" || this.filtros.solicitud == "C"){
          if(this.numeroSolicitudAtendida == item.numeroSolicitud || this.numeroSolicitudAtendida == "" ){
            this.numeroSolicitudDetalle = item.numeroSolicitud;
            this.itemSeleccionadoDetalle = item;
            this.cantidadSolicitadaDetalle = this.itemSeleccionadoDetalle.cantidadSolicitada;
            this.numeroSolicitudDetalle = this.itemSeleccionadoDetalle.numeroSolicitud;
            this.nombreServicioDetalle = this.itemSeleccionado.ubicacionServicio;
            this.informacionAlternativas = {};
            this.informacionLotes = {};
            this.inputCantidad = "";
            this.codigoTripleA = "",
            this.informacionUbicacion = {};
            this.dialogEntrega = true;
            await this.getAlternativas();
            await this.postPreRemision();
            this.botonFilaDetalle = false;
          }else{
            this.mensajeInformativo = "No se puede atender dos pedidos con numero de solicitud diferente";
            this.snackbarVisible = true;
            this.colorSnackbar = "amber darken-1";
            this.timeSnackbar = 8000
            this.botonFilaDetalle = false;
          }
        }else{
          this.numeroSolicitudDetalle = item.numeroSolicitud;
          this.desactivarUbicacionDevolucion = true;
          this.desactivarMotivoDevolucion = true;
          this.itemSeleccionadoDetalle = item;
          this.dialogDevolucion = true;
          await this.postPreRemision();
          this.botonFilaDetalle = false;
        }
      }else{
        let parrafos = this.respuestaValidarUsuario.message.split(":");
        this.mensajeInformativo = parrafos[0] + ": <b>" + parrafos[1] + "</b>";
        this.snackbarVisible = true;
        this.colorSnackbar = "amber darken-1";
        this.timeSnackbar = 5000;
        this.botonFilaDetalle = false;
      }
    },

    seleccionFiltroUbicacion(item){
      return item.nombreUbicacion;
    },
    seleccionCantidades(item) {
      if(this.codigoBodega == 5153){
        return `${item.lote} ~ ${item.cantidadHerinco} ~ ${item.cantidadStone} ~ ${item.fechaVencimiento} ~ ${item.codAaa} `;
      }else{
        return `${item.lote} ~ ${item.cantidadHerinco} ~ ${item.cantidadStone} ~ ${item.fechaVencimiento} `;
      }
    },
    seleccionNombreCohan(item) {
      return `${item.codigoProducto} ~ ${item.nombreProducto}`;
    },
    selectUbicacionMedicamento(item){
      return item.ubicacion;
    },
    seleccionUbicacion(item) {
      return item.descripcion;
    },
    seleccionMotivo(item) {
      return item.descripcion;
    },

    getAlternativas(){
      return new Promise((resolve) => {
      let codigo = this.itemSeleccionadoDetalle.codigoGenerico;
      let bodega = this.codigoBodega;
      this.$http.get("msa-pharmaceutical-services/process/alternativas/"+codigo+"/"+bodega+"/"+this.enterprise.code, {
        }).then(result =>{
          this.respuestaAlternativas = result.data;
          this.selectUbicacion = {};
          this.inputTripleAactivar = true;
          this.inputCantidadActivar = true;
          this.inputUbicacionMotivo = true;
          this.desactivarMotivo = true;
          resolve(true)
          }).catch(() => {
            this.botonFilaDetalle = false;
          })
        }) 
    },

    getUbicacionesMedicamentos(){
      if(this.informacionAlternativas?.codigoProducto != null){
      this.$http.get("msa-pharmaceutical-services/process/ubicaciones/" + this.codigoBodega + "/" + this.enterprise.code
      ).then((result) =>{
        this.ubicacionesMedicamentos = result.data;
        this.inputUbicacionActivar = false;
      })
    }
    },

    postPreRemision() {
      return new Promise((resolve) => {
        if (this.filtros.solicitud == 'S') {
          this.dialogDetalle = true;
          this.$http.post("msa-pharmaceutical-services/process/preentrega", {
              empresa: this.enterprise.code,
              usuario: this.nombreUsuario,
              bodega: this.codigoBodega,
              numeroSolicitud: this.numeroSolicitudDetalle,
              ingreso: this.numeroIngresoDetalle,
              codigoUbicacion: "",
              paciente: this.numeroIdentificacionDetalle,
              servicio: this.nombreServicioDetalle,
              tipoSolicitud: "S"
            }).then((result) => {
              console.log(result.data)
                this.respuestaPreRemision = result.data;
                this.rmtCare = result.data.rmtCare;
                this.idDespacho = result.data.idDespacho;
                resolve(true)
              }).catch(() => {
                this.botonFilaDetalle = false;
              })
        }
        if (this.filtros.solicitud == 'C') {
          this.dialogDetalle = true;
          this.$http.post("msa-pharmaceutical-services/process/preentrega", {
            empresa: this.enterprise.code,
            usuario: this.nombreUsuario,
            bodega: this.codigoBodega,
            numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
            ingreso: 0,
            codigoUbicacion: this.itemSeleccionado.codigoUbicacion,
            paciente: '',
            servicio: this.nombreServicioDetalle,
            tipoSolicitud: "C"
          }).then((result) => {
            console.log( result.data)
              this.respuestaPreRemision = result.data;
              this.rmtCare = result.data.rmtCare;
              this.idDespacho = result.data.idDespacho;
              resolve(true)
            }).catch(() => {
                this.botonFilaDetalle = false;
            })
        }
        if (this.filtros.solicitud == 'D') {
          let solicitudFinal;
          if(this.itemSeleccionado.tipoSolicitud == 'Solicitud'){
            solicitudFinal = "S"
          } else {
            solicitudFinal = "C"
          }
          this.$http.post("msa-pharmaceutical-services/process/predevolucion", {
              empresa: this.enterprise.code,
              usuario: this.nombreUsuario,
              bodega: this.codigoBodega,
              idDevolucion: this.itemSeleccionado.idDevolucion,
              numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
              ingreso: this.itemSeleccionado.numeroIngreso,
              codigoUbicacion: this.itemSeleccionado.codigoUbicacion,
              tipoSolicitud: solicitudFinal
            }).then((result) => {
                console.log(result.data)
                this.respuestaPreRemision = result.data;
                this.rmtCadv = result.data.rmtCadv;
                this.idDevuelto = result.data.idDevolucion;
                resolve(true)
              }).catch(() => {
                this.botonFilaDetalle = false;
              })
        }
      })
    },

    postCantidades() {
      this.dialogEntrega = true;
      if(this.respuestaAlternativas.length == 0){
        this.mensajeInformativo = "No hay existencias para este producto";
        this.snackbarVisible = true;
        this.colorSnackbar = "orange";
        this.timeSnackbar = 8000;
      }

      if (this.filtros.solicitud == 'S' && this.selectUbicacion?.ubicacion != null ) {
        this.$http.post("msa-pharmaceutical-services/process/cantidades", {
          empresa: this.enterprise.code,
          producto: this.informacionAlternativas?.codigoProducto,
          rmtUbic: this.selectUbicacion?.rmtUbic,
          bodega: this.codigoBodega
        }).then((result) => {
            this.respuestaCantidades = result.data;
            this.selectLote = false;
          })
      }
      if (this.filtros.solicitud == 'C' && this.selectUbicacion?.ubicacion != null) {
        this.$http.post("msa-pharmaceutical-services/process/cantidades", {
          empresa: this.enterprise.code,
          producto: this.informacionAlternativas?.codigoProducto,
          rmtUbic: this.selectUbicacion?.rmtUbic,
          bodega: this.codigoBodega
        }).then((result) => { 
          this.respuestaCantidades = result.data;
          this.selectLote = false;
          })
      }

    },

    postAgregarProducto() {
      this.desactivarBotonAgregar = true;
      if (moment(this.informacionLotes.fechaVencimiento).isBefore(moment()) && (this.filtros.solicitud == "S" || this.filtros.solicitud == "C") ) {
        this.mensajeInformativo = "La fecha de vencimiento del producto escogido no es valida";
        this.snackbarVisible = true;
        this.colorSnackbar = "red";
        this.timeSnackbar = 8000; 
        return;
      }
      if(this.filtros.solicitud == "S" || this.filtros.solicitud == "C"){
        if(this.informacionAlternativas?.codigoProducto != null &&
          this.informacionLotes?.rmtUbic != null &&
          this.informacionLotes?.lote != null &&
          this.inputCantidad != "" ){
          if(this.inputCantidad > 0){
            if((parseInt(this.inputCantidad) + this.itemSeleccionadoDetalle.cantidadEntregada) <= this.itemSeleccionadoDetalle.cantidadSolicitada){
              if (this.filtros.solicitud == 'S') {
                this.$http.post("msa-pharmaceutical-services/process/agregarproducto", {
                  empresa: this.enterprise.code,
                  bodega: this.codigoBodega,
                  usuario: this.nombreUsuario,
                  producto: this.informacionAlternativas?.codigoProducto,
                  cantidad: this.inputCantidad,
                  rmtUbic: this.informacionLotes?.rmtUbic,
                  lote: this.informacionLotes?.lote,
                  fechaVencimiento: this.informacionLotes?.fechaVencimiento,
                  tipoSolicitud: "S",
                  consecutivo: this.rmtCare,
                  consecutivoId: this.idDespacho,
                  numeroSolicitud: this.itemSeleccionadoDetalle.numeroSolicitud,
                  ingreso: this.itemSeleccionado.numeroIngreso,
                  ubicacionMotivo: this.informacionUbicacion.id,
                  motivo: this.informacionMotivo.id,
                  rmtAaa: this.informacionLotes.rmtAaa
                }).then((result) => {
                  console.log(result.data)
                  this.respuestaAgregarProducto = result.data;
                  this.numeroSolicitudAtendida = this.itemSeleccionadoDetalle.numeroSolicitud;
                  this.getTablaDetalles();
                  this.resetearVariables();
                  if(this.respuestaAgregarProducto.response == true){
                    this.mensajeInformativo = this.respuestaAgregarProducto.message;
                    this.snackbarVisible = true;
                    this.colorSnackbar = "green";
                    this.activarBotonEntregas = false;
                    this.activarBotonAplazar = false;
                    this.timeSnackbar = 5000;
                    this.desactivarBotonAgregar = false;
                  }else{
                    this.mensajeInformativo = this.respuestaAgregarProducto.message;
                    this.snackbarVisible = true;
                    this.colorSnackbar = "red";
                    this.timeSnackbar = 5000;
                    this.activarBotonEntregas = true;
                    this.activarBotonAplazar = true;
                    this.desactivarBotonAgregar = false;
                  }
                }).catch((error) => {
                  console.log(error)
                  this.mensajeInformativo = "Ocurrió un error inesperado al agregar la entrega";
                  this.snackbarVisible = true;
                  this.colorSnackbar = "red";
                  this.timeSnackbar = 8000;
                  this.getTablaDetalles();
                  this.resetearVariables();
                  this.desactivarBotonAgregar = false;
                })
              } 
                
              if (this.filtros.solicitud == 'C') {
                if(this.codigoTripleA == this.informacionLotes?.codAaa){
                  this.$http.post("msa-pharmaceutical-services/process/agregarproducto", {
                    empresa: this.enterprise.code,
                    bodega: this.codigoBodega,
                    usuario: this.nombreUsuario,
                    producto: this.informacionAlternativas?.codigoProducto,
                    cantidad: this.inputCantidad,
                    rmtUbic: this.informacionLotes.rmtUbic,
                    lote: this.informacionLotes.lote,
                    fechaVencimiento: this.informacionLotes.fechaVencimiento,
                    tipoSolicitud: "C",
                    consecutivo: this.rmtCare,
                    consecutivoId: this.idDespacho,
                    numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
                    codigoUbicacion: this.itemSeleccionado.codigoUbicacion,
                    ingreso: 0,
                    ubicacionMotivo: this.informacionUbicacion.id,
                    motivo: this.informacionMotivo.id,
                    rmtAaa: this.informacionLotes.rmtAaa
                  }).then((result) => {
                    this.respuestaAgregarProducto = result.data;
                    this.numeroSolicitudAtendida = "",
                    this.getTablaDetalles();
                    this.resetearVariables();
                    if(this.respuestaAgregarProducto.response == true){
                      this.mensajeInformativo = this.respuestaAgregarProducto.message;
                      this.snackbarVisible = true;
                      this.colorSnackbar = "green";
                      this.timeSnackbar = 5000;
                      this.activarBotonEntregas = false;
                      this.activarBotonAplazar = false;
                      this.desactivarBotonAgregar = false;
                    }else{
                      this.mensajeInformativo = this.respuestaAgregarProducto.message;
                      this.snackbarVisible = true;
                      this.colorSnackbar = "red";
                      this.timeSnackbar = 5000;
                      this.activarBotonEntregas = true;
                      this.activarBotonAplazar = true;
                      this.desactivarBotonAgregar = false;
                    }
                  }).catch(() => {
                    this.mensajeInformativo = "Ocurrio un error inesperado al agregar la entrega";
                    this.snackbarVisible = true;
                    this.colorSnackbar = "red";
                    this.timeSnackbar = 8000;
                    this.getTablaDetalles();
                    this.resetearVariables();
                    this.desactivarBotonAgregar = false;
                  })
                }else{
                  this.mensajeInformativo = "Los codigos triple A no coinciden";
                  this.snackbarVisible = true;
                  this.colorSnackbar = "red";
                  this.timeSnackbar = 8000;
                  this.desactivarBotonAgregar = false;
                }
              }
            }else{
              this.mensajeInformativo = "La cantidad entregada no puede ser mayor que la solicitada";
              this.snackbarVisible = true;
              this.colorSnackbar = "red";
              this.timeSnackbar = 8000;
              this.desactivarBotonAgregar = false;
            }  
          }else{
              this.mensajeInformativo = "La cantidad a entregar debe ser mayor a 0";
              this.snackbarVisible = true;
              this.colorSnackbar = "red";
              this.timeSnackbar = 8000;
              this.desactivarBotonAgregar = false;
          } 
            
        }else{
          this.mensajeInformativo = "Debe llenar correctamente todos los campos para hacer la entrega";
          this.snackbarVisible = true;
          this.colorSnackbar = "red";
          this.timeSnackbar = 8000;
        }
      }

      if(this.filtros.solicitud == 'D') {
        if(this.inputCantidadDevuelta > 0){
          if((parseInt(this.inputCantidadDevuelta) + this.itemSeleccionadoDetalle.cantidadAceptada) <= this.itemSeleccionadoDetalle.cantidadDevuelta){
            this.$http.post("msa-pharmaceutical-services/process/agregarproductodevolucion", {
              empresa: this.enterprise.code,
              bodega: this.codigoBodega,
              usuario: this.nombreUsuario,
              producto: this.itemSeleccionadoDetalle.codigoProducto,
              cantidad: this.inputCantidadDevuelta,
              lote: this.itemSeleccionadoDetalle.lote,
              fechaVencimiento: this.itemSeleccionadoDetalle.fechaVencimiento,
              consecutivo: this.rmtCadv,
              consecutivoId: this.idDevuelto,
              numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
              ingreso: this.itemSeleccionado.numeroIngreso,
              codigoUbicacion: this.itemSeleccionado.codigoUbicacion,
              ubicacionMotivo: this.informacionUbicacion.id,
              motivo: this.informacionMotivo.id
            }).then((result) => {
              console.log(result.data)
              this.respuestaAgregarProducto = result.data;
              this.dialogDevolucion = false;
              this.numeroSolicitudAtendida = "";
              this.getTablaDetalles();
              this.cerrarDialogdevolucion()
              if(this.respuestaAgregarProducto.response == true){
                this.mensajeInformativo = this.respuestaAgregarProducto.message;
                this.snackbarVisible = true;
                this.colorSnackbar = "green";
                this.timeSnackbar = 5000;
                this.activarBotonEntregas = false;
                this.activarBotonAplazar = false;
                this.desactivarBotonAgregar = false;
              }else{
                this.mensajeInformativo = this.respuestaAgregarProducto.message;
                this.snackbarVisible = true;
                this.colorSnackbar = "red";
                this.timeSnackbar = 5000;
                this.activarBotonEntregas = true;
                this.activarBotonAplazar = true;
                this.desactivarBotonAgregar = false;
              }
            }).catch((error) => {
              console.log(error)
              this.mensajeInformativo = "Ocurrió un error inesperado al agregar la entrega";
              this.snackbarVisible = true;
              this.colorSnackbar = "red";
              this.timeSnackbar = 8000;
              this.getTablaDetalles();
              this.cerrarDialogdevolucion()
              this.desactivarBotonAgregar = false;
            })
          }else{
            this.mensajeInformativo = "La cantidad aceptada no puede ser mayor a la cantidad devuelta";
            this.snackbarVisible = true;
            this.colorSnackbar = "red";
            this.timeSnackbar = 8000;
          }
        }else{
            this.mensajeInformativo = "La cantidad a devolver debe ser mayor a 0";
            this.snackbarVisible = true;
            this.colorSnackbar = "red";
            this.timeSnackbar = 8000;
          }
      }
    },

    postCerrarEntrega(){
      if(this.respuestaValidarUsuario.response == true){
        this.informacionTablaDetalle = [];
        this.tablaAnimacion = true;
        if (this.filtros.solicitud == 'S'){
          this.activarBotonEntregas = true;
          this.activarBotonAplazar = true;
          this.$http.post("msa-pharmaceutical-services/process/cerrarentrega", {
          empresa: this.enterprise.code,
            bodega: this.codigoBodega,
            usuario: this.nombreUsuario,
            ingreso: this.numeroIngresoDetalle,
            codigoUbicacion: "", 
            numeroSolicitud: this.numeroSolicitudDetalle,
            tipoSolicitud:"S",
            rmtCare: this.rmtCare
          }).then((result) => {
            this.respuestaCerrarEntrega = result.data;
            console.log(this.respuestaCerrarEntrega)
            if(this.respuestaCerrarEntrega.estado == 1){
              this.mensajeInformativo =this.respuestaCerrarEntrega.mensaje;
              this.snackbarVisible = true; 
              this.colorSnackbar = "green";
              this.timeSnackbar = 5000;
              this.numeroSolicitudAtendida = "";
              this.dialogDescarga = true;
              this.getTablaDetalles();
            }else{
              this.dialogDescarga = false;
              this.mensajeInformativo = this.respuestaCerrarEntrega.mensaje;
              this.snackbarVisible = true; 
              this.colorSnackbar = "red";
              this.timeSnackbar = 5000;
              this.getTablaDetalles();
            }
          }).catch((error) => {
            this.mensajeInformativo = "Ocurrió un error inesperado al hacer la entrega";
            this.snackbarVisible = true; 
            this.colorSnackbar = "red";
            this.timeSnackbar = 8000;
            console.log(error)
          })
        }
        if(this.filtros.solicitud == 'C'){
          this.activarBotonEntregas = true;
          this.activarBotonAplazar = true;
          this.$http.post("msa-pharmaceutical-services/process/cerrarentrega", {
            empresa: this.enterprise.code,
            bodega: this.codigoBodega,
            usuario: this.nombreUsuario,
            ingreso: 0,
            codigoUbicacion: this.itemSeleccionado.codigoUbicacion, 
            numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
            tipoSolicitud:"C",
            rmtCare: this.rmtCare
          }).then((result) => {
            this.respuestaCerrarEntrega = result.data;
            if(this.respuestaCerrarEntrega.estado == 1){
              this.mensajeInformativo = this.respuestaCerrarEntrega.mensaje;
              this.snackbarVisible = true; 
              this.colorSnackbar = "green";
              this.timeSnackbar = 5000;
              this.dialogDescarga = true;
              this.getTablaDetalles();
            }else{
              this.dialogDescarga = false;
              this.mensajeInformativo = this.respuestaCerrarEntrega.mensaje;
              this.snackbarVisible = true; 
              this.colorSnackbar = "red";
              this.timeSnackbar = 5000;
              this.getTablaDetalles();
            }
          }).catch((error) => {
            this.mensajeInformativo = "Ocurrió un error inesperado al hacer la entrega";
            this.snackbarVisible = true; 
            this.colorSnackbar = "red";
            this.timeSnackbar = 8000;
            this.log(error)
          })
        }
        if(this.filtros.solicitud == 'D'){
          let solicitudFinal;
          if(this.itemSeleccionado.tipoSolicitud == 'Solicitud'){
            solicitudFinal = "S"
          } else {
            solicitudFinal = "C"
          }
          this.activarBotonEntregas = true;
          this.activarBotonAplazar = true;
          this.$http.post("msa-pharmaceutical-services/process/cerrardevolucion", {
            empresa: this.enterprise.code,
            bodega: this.codigoBodega,
            usuario: this.nombreUsuario,
            ingreso: this.itemSeleccionado.numeroIngreso,
            codigoUbicacion: this.itemSeleccionado.codigoUbicacion, 
            numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
            tipoSolicitud: solicitudFinal,
            rmtCadv: this.rmtCadv
          }).then((result) => {
            this.respuestaCerrarEntrega = result.data;
            if(this.respuestaCerrarEntrega.estado == 1){
          this.dialogDescarga = true;
              this.mensajeInformativo = this.respuestaCerrarEntrega.mensaje;
              this.snackbarVisible = true; 
              this.colorSnackbar = "green";
              this.timeSnackbar = 5000;
              this.getTablaDetalles();
            }else{
          this.dialogDescarga = false;
              this.mensajeInformativo = this.respuestaCerrarEntrega.mensaje;
              this.snackbarVisible = true; 
              this.colorSnackbar = "red";
              this.timeSnackbar = 5000;
              this.getTablaDetalles();
            }
          }).catch((error) => {
            this.mensajeInformativo = "Ocurrió un error inesperado al hacer la entrega";
            this.snackbarVisible = true; 
            this.colorSnackbar = "red";
            this.timeSnackbar = 8000;
            this.log(error)
          })
        }
      }else{
        let parrafos = this.respuestaValidarUsuario.message.split(":");
        this.snackbarVisible = true;
        this.mensajeInformativo = "La transaccion numero: " + this.itemSeleccionado.numeroSolicitud + " solo puede ser entregada por el usuario" + ": <b>" + parrafos[1] + "</b>";
        this.colorSnackbar = "red";
        this.timeSnackbar = 8000;
      }
    },

    getMotivos(tipoMotivo){
      this.$http.get("msa-pharmaceutical-services/respuestaMotivo", {
        params:{
          motivo: tipoMotivo
        }
      }).then((result => {
        if(tipoMotivo == 'U'){
          this.respuestaUbicacion = result.data;
        }
        if(tipoMotivo == 'M'){
          this.respuestaMotivo = result.data;
        } 
        if(tipoMotivo == 'D'){
          this.respuestaMotivo = result.data;
        } 
      }))
    },

    GetSolicitudesIncompletas(){
      if(this.filtros.solicitud == "S"){
        return new Promise((resolve) => {
          this.$http.get("msa-pharmaceutical-services/monitorSolicitudesValidar", {
            params:{
              numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
            }
          }).then((result) => {
            this.respuestaDetallesEstados = result.data;  
            resolve(true); 
         }) 
        })  
     }
      if(this.filtros.solicitud == "C"){
      return new Promise((resolve) => {
        this.$http.get("msa-pharmaceutical-services/monitorConsumosValidar", {
          params:{
            idConsumo: this.idConsumo
          }
        }).then((result) => {
          this.respuestaDetallesEstados = result.data;  
          resolve(true); 
        }) 
      })  
    }
      if(this.filtros.solicitud == "D"){
      return new Promise((resolve) => {
        this.$http.get("msa-pharmaceutical-services/monitorDevolucionesValidar", {
          params:{
            idDevolucion: this.itemSeleccionado.idDevolucion
          }
        }).then((result) => {
          this.respuestaDetallesEstados = result.data;  
          resolve(true); 
        }) 
      })  
    }


  },
    postPosponerEntregas(){
      if(this.respuestaValidarUsuario.response == true){
        if(this.filtros.solicitud == "S" || this.filtros.solicitud == "C"){
          this.$http.post("msa-pharmaceutical-services/process/posponerentrega", {
            empresa: this.enterprise.code,
            bodega: this.codigoBodega,
            usuario: this.nombreUsuario,
            tipoSolicitud: this.filtros.solicitud,
            rmtCare: this.rmtCare,
            idDespacho: this.idDespacho
          }).then((result) => {
            this.respuestaCancelacion = result.data; 
            console.log(this.respuestaCancelacion); 
            if(this.respuestaCancelacion.response == true ){
            this.activarBotonEntregas = true;
            this.activarBotonAplazar = true;
              this.getTablaDetalles();
              this.snackbarVisible = true;
              this.mensajeInformativo = result.data.message;
              this.timeSnackbar = 5000;
              this.colorSnackbar = "green";  
            }else{
              this.activarBotonEntregas = true;
              this.activarBotonAplazar = true;
              this.getTablaDetalles();
              this.snackbarVisible = true;
              this.mensajeInformativo = result.data.message;
              this.timeSnackbar = 5000;
              this.colorSnackbar = "red";  
            }
          }).catch(() => {
            this.snackbarVisible = true;
            this.mensajeInformativo = "Error al aplazar la entrega";
            this.colorSnackbar = "red";
            this.timeSnackbar = 8000;

          })
        }else{
          let solicitudFinal;
          if(this.itemSeleccionado.tipoSolicitud == 'Solicitud'){
            solicitudFinal = "S"
          } else {
            solicitudFinal = "C"
          }
          this.$http.post("msa-pharmaceutical-services/process/posponerdevolucion", {
          empresa: this.enterprise.code,
            bodega: this.codigoBodega,
            usuario: this.nombreUsuario,
            tipoSolicitud: solicitudFinal,
            rmtCadv: this.rmtCadv,
            idDevolucion: this.idDevuelto
          }).then((result) => {
            this.respuestaCancelacion = result.data; 
            console.log(this.respuestaCancelacion); 
            if(this.respuestaCancelacion.response == true ){
              this.activarBotonEntregas = true;
              this.activarBotonAplazar = true;
              this.getTablaDetalles();
              this.snackbarVisible = true;
              this.mensajeInformativo = result.data.message;
              this.colorSnackbar = "green";  
              this.timeSnackbar = 5000;
            }
            if(this.respuestaCancelacion.response == false ){
              this.activarBotonEntregas = true;
              this.activarBotonAplazar = true;
              this.getTablaDetalles();
              this.snackbarVisible = true;
              this.mensajeInformativo = result.data.message;
              this.colorSnackbar = "red";  
              this.timeSnackbar = 5000;
            }
          }).catch(() => {
            this.snackbarVisible = true;
            this.mensajeInformativo = "Error al aplazar la entrega";
            this.colorSnackbar = "red";
            this.timeSnackbar = 8000;

          })

        }
      }else{
        let parrafos = this.respuestaValidarUsuario.message.split(":");
        this.snackbarVisible = true;
        this.mensajeInformativo = "La transaccion numero: " + this.itemSeleccionado.numeroSolicitud + " solo puede ser aplazada por el usuario" + ": <b>" + parrafos[1] + "</b>";
        this.colorSnackbar = "red";
        this.timeSnackbar = 8000;
      }
    },

    validarCierreSolicitud(){
      if(this.respuestaDetallesEstados[0]?.numeroSolicitud != null){
        this.$http.get("msa-pharmaceutical-services/process/buscarPorConsecutivo", {
          params:{
            numeroSolicitud: this.respuestaDetallesEstados[0]?.numeroSolicitud
          }
        }).then((result) => {
          if(result.data == true && (this.filtros.solicitud == 'S' || this.filtros.solicitud == 'C')){
            this.activarBotonAplazar = true;
          }
        })
      }
    },

    async ValidarEstados(){
      this.validarCierreSolicitud();
      if(this.respuestaDetallesEstados.length != 0) {
        console.log(this.respuestaDetallesEstados)
        if(this.filtros.solicitud == 'S'){
          this.activarBotonEntregas = false;
          this.activarBotonAplazar = false;
          this.snackbarVisible = true;
          this.mensajeInformativo = "La solicitud " + this.respuestaDetallesEstados[0].numeroSolicitud + " está en proceso de entrega, agregue más productos o realice la entrega.";
          this.colorSnackbar = "amber darken-1";
          this.timeSnackbar = 8000;
          this.numeroSolicitudAtendida = this.respuestaDetallesEstados[0].numeroSolicitud;
          this.numeroSolicitudDetalle = this.respuestaDetallesEstados[0].numeroSolicitud;
          this.numeroIngresoDetalle = this.respuestaDetallesEstados[0].numeroIngreso;
          this.nombreServicioDetalle = this.respuestaDetallesEstados[0].ubicacionServicio;
          /* await this.postValidarUsuarios();
          if(this.respuestaValidarUsuario.response == true){
            await this.postPreRemision();
          } */
            this.getTablaDetalles();
        }
        if(this.filtros.solicitud == 'C'){
          this.activarBotonEntregas = false;
          this.activarBotonAplazar = false;
          this.snackbarVisible = true;
          this.mensajeInformativo = "La solicitud " + this.respuestaDetallesEstados[0].numeroSolicitud + " está en proceso de entrega, agregue más productos o realice la entrega. ";
          this.colorSnackbar = "amber darken-1";
          this.timeSnackbar = 8000;
          this.numeroSolicitudDetalle = this.respuestaDetallesEstados[0].numeroSolicitud;
          this.nombreServicioDetalle = this.respuestaDetallesEstados[0].ubicacionServicio;
          /* await this.postValidarUsuarios();
          if(this.respuestaValidarUsuario.response == true){
            await this.postPreRemision();
          } */
            this.getTablaDetalles();
        }
        if(this.filtros.solicitud == 'D'){
          if(this.respuestaDetallesEstados[0].estado == 'Parcial'){
            this.activarBotonAplazar = true;
          }else{
            this.activarBotonAplazar = false;
          }
          this.activarBotonEntregas = false;
          this.snackbarVisible = true;
          this.mensajeInformativo = "La solicitud " + this.itemSeleccionado.numeroSolicitud + " está en proceso de devolución, agregue más productos o realice la devolución.";
          this.colorSnackbar = "amber darken-1";
          this.timeSnackbar = 8000;
          this.numeroSolicitudDetalle = this.respuestaDetallesEstados[0].numeroSolicitud;
          this.nombreServicioDetalle = this.respuestaDetallesEstados[0].ubicacionServicio;
          /* await this.postValidarUsuarios();
          if(this.respuestaValidarUsuario.response == true){
            await this.postPreRemision();
          } */
            this.getTablaDetalles();
        } 
      }else{
        this.getTablaDetalles();
      }
    },

    postValidarUsuarios(){
      if(this.filtros.solicitud == "S"){
        return new Promise((resolve) => {
          this.$http.post("msa-pharmaceutical-services/process/validarentrega", {
            empresa: this.enterprise.code,
            usuario: this.nombreUsuario,
            bodega: this.codigoBodega,
            numeroSolicitud: this.numeroSolicitudDetalle,
            ingreso: this.numeroIngresoDetalle,
            codigoUbicacion: "",
            paciente: this.numeroIdentificacionDetalle,
            servicio: this.nombreServicioDetalle,
            tipoSolicitud: "S"
          }).then((result) => {
            console.log("aqui empieza la validacion")
            console.log(result.data)
            this.respuestaValidarUsuario = result.data
            if(result.data.response == true){
              resolve(true)
            }else{
              resolve(false)
            }
          }).catch((error) => {
            console.log(error)
            this.botonFilaDetalle = false;
          })
        })
      }
      if(this.filtros.solicitud == "C"){
        console.log("valido C")
        return new Promise((resolve) => {
          this.$http.post("msa-pharmaceutical-services/process/validarentrega", {
            empresa: this.enterprise.code,
            usuario: this.nombreUsuario,
            bodega: this.codigoBodega,
            numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
            ingreso: 0,
            codigoUbicacion: this.itemSeleccionado.codigoUbicacion,
            paciente: '',
            servicio: this.itemSeleccionado.nombreServicio,
            tipoSolicitud: "C"
          }).then((result) => {
            console.log(result.data)
            this.respuestaValidarUsuario = result.data
            if(result.data.response == true){
              resolve(true)
            }else{
              resolve(false)
            }
          }).catch((error) => {
            console.log(error)
            this.botonFilaDetalle = false;
          })
        })
      }
      if(this.filtros.solicitud == "D"){
        console.log("valido D")
        return new Promise((resolve) => {
          let solicitudFinal;
          if(this.itemSeleccionado.tipoSolicitud == 'Solicitud'){
            solicitudFinal = "S"
          } else {
            solicitudFinal = "C"
          }

          this.$http.post("msa-pharmaceutical-services/process/validardevolucion",{
            empresa: this.enterprise.code,
            usuario: this.nombreUsuario,
            bodega: this.codigoBodega,
            idDevolucion: this.itemSeleccionado.idDevolucion,
            numeroSolicitud: this.itemSeleccionado.numeroSolicitud,
            ingreso: this.itemSeleccionado.numeroIngreso,
            codigoUbicacion: this.itemSeleccionado.codigoUbicacion,
            tipoSolicitud: solicitudFinal
          }).then((result) => {
            console.log(result.data)
            this.respuestaValidarUsuario = result.data
            if(result.data.response == true){
              resolve(true)
            }else{
              resolve(false)
            }
          }).catch((error) => {
            console.log(error)
            this.botonFilaDetalle = false;
          })
        })
      }
    },

    resetearVariables(){
      this.inputUbicacionActivar = true;
      this.selectLote = true;
      this.inputTripleAactivar = true;
      this.inputCantidadActivar = true;
      this.inputUbicacionMotivo = true;
      this.desactivarMotivo = true;
      this.informacionMotivo = {};
      this.dialogEntrega = false;
    },

    validarCierreDetalle(){ 
        this.dialogDetalle = false;
        this.numeroSolicitudAtendida = "";
        this.getTablaPrincipal();
    },

    cerrarDialogdevolucion(){
      this.dialogDevolucion = false;
      this.inputCantidadDevuelta = "";
      this.informacionUbicacion = {};
      this.informacionMotivo = {};
    },

    descargarPdf() {
       // Obtener la fecha y hora actual
      const fechaActual = new Date();
      // Formatear la fecha y hora a yyyyMMdd_HH:mm
      const year = fechaActual.getFullYear();
      const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
      const day = String(fechaActual.getDate()).padStart(2, '0');
      const hours = String(fechaActual.getHours()).padStart(2, '0');
      const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
      const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
      const fechaFormateada = `${year}${month}${day}_${hours}:${minutes}:${seconds}`;
      if(this.filtros.solicitud == 'S'){
        this.$http.get(`msa-pharmaceutical-services/descargar`, {
        responseType: 'blob', // important
        params:{
          tipoSolicitud: this.filtros.solicitud,
          usuario: this.nombreUsuario,
          numeroSolicitud: this.numeroSolicitudDetalle
        }
      }).then((result) => {
          const nombreArchivo = `Entrega_${fechaFormateada}.pdf`;
          const href = URL.createObjectURL(result.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', nombreArchivo);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.dialogDescarga = false;
        })
        .catch((error) => {
          console.log(error);
          this.dialogDescarga = false;
          this.snackbarVisible = true;
          this.mensajeInformativo = "Ocurrio un error inesperado al descargar el documento";
          this.colorSnackbar = "red";
          this.timeSnackbar = 5000;
        });
      }
      if(this.filtros.solicitud == 'C'){
        this.$http.get(`msa-pharmaceutical-services/descargar`, {
        responseType: 'blob', // important
        params:{
          tipoSolicitud: this.filtros.solicitud,
          usuario: this.nombreUsuario,
          numeroSolicitud: this.itemSeleccionado.numeroSolicitud
        }
      }).then((result) => {
          const nombreArchivo = `Entrega_${fechaFormateada}.pdf`;
          const href = URL.createObjectURL(result.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', nombreArchivo);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.dialogDescarga = false;
        })
        .catch((error) => {
          console.log(error);
          this.dialogDescarga = false;
          this.snackbarVisible = true;
          this.mensajeInformativo = "Ocurrio un error inesperado al descargar el documento";
          this.colorSnackbar = "red";
          this.timeSnackbar = 5000;
        });
      }
      if(this.filtros.solicitud == 'D'){
        this.$http.get(`msa-pharmaceutical-services/descargar`, {
        responseType: 'blob', // important
        params:{
          tipoSolicitud: this.filtros.solicitud,
          usuario: this.nombreUsuario,
          numeroSolicitud: this.itemSeleccionado.numeroSolicitud
        }
      }).then((result) => {
          const nombreArchivo = `Devolucion_${fechaFormateada}.pdf`;
          const href = URL.createObjectURL(result.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', nombreArchivo);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.dialogDescarga = false;
        })
        .catch((error) => {
          console.log(error);
          this.dialogDescarga = false;
          this.snackbarVisible = true;
          this.mensajeInformativo = "Ocurrio un error inesperado al descargar el documento";
          this.colorSnackbar = "red";
          this.timeSnackbar = 5000;
        });
      }
    },

  },
  watch: {
    "filtros.solicitud": function () {
      this.informacionTablaPrincipal = [];
      if (this.filtros.solicitud == 'S') {
        this.getUbicacionesFiltro();
        this.botonAplazar = "APLAZAR ENTREGA";
        this.botonRealizar = "REALIZAR ENTREGA";
        this.informacionUbicacion = {};
        this.informacionMotivo = {};
        this.getMotivos('U');
        this.getMotivos('M');
        this.fechas = [];
        this.estado = "";
        this.ubicacionServicio = "";
        this.numeroIngreso = "";
        this.nombrePaciente = "";
        this.cama = "";
        this.numeroDocumento = "";
        this.informacionUbicacionFiltro = [];
        this.filtrosSolicitud = true;
        this.filtrosConsumo = true;
        this.filtrosDevolucion = false;
        this.headersInformacionPrincipal = headers.headerSolicitud;
        this.headersInformacionDetalle = headers.headerSolicitudDetalle;
      }

      if (this.filtros.solicitud == 'C') {
        this.getUbicacionesFiltro();
        this.botonAplazar = "APLAZAR ENTREGA";
        this.botonRealizar = "REALIZAR ENTREGA";
        this.informacionUbicacion = {};
        this.informacionMotivo = {};
        this.getMotivos('U');
        this.getMotivos('M');
        this.numeroSolicitud = "";
        this.fechas = [];
        this.estado = "";
        this.ubicacionServicio = "";
        this.informacionUbicacionFiltro = [];
        this.filtrosSolicitud = true;
        this.filtrosConsumo = false;
        this.filtrosDevolucion = false;
        this.headersInformacionPrincipal = headers.headerConsumo;
        this.headersInformacionDetalle = headers.headerConsumoDetalle;
      }

      if (this.filtros.solicitud == 'D') {
        if (this.codigoBodega == 5103 || this.codigoBodega == 5123 ){
          this.headersInformacionPrincipal = headers.headerDevolucion;
          this.headersInformacionDetalle = headers.headerDevolucionDetalle;
        }else{
          this.headersInformacionPrincipal = headers.headerDevolucionConsumo;
          this.headersInformacionDetalle = headers.headerDevolucionDetalle;
        }
        this.getUbicacionesFiltro();
        this.botonAplazar = "APLAZAR DEVOLUCIÓN";
        this.botonRealizar = "REALIZAR DEVOLUCIÓN";
        this.informacionUbicacion = {};
        this.informacionMotivo = {};
        this.getMotivos('U');
        this.getMotivos('D');
        this.numeroSolicitud = "";
        this.fechas = [];
        this.estado = "";
        this.ubicacionServicio = "";
        this.informacionUbicacionFiltro = [];
        this.filtrosSolicitud = true;
        this.filtrosConsumo = false;
        this.filtrosDevolucion = true;
      }
    },

    "page": function () {
      this.getTablaPrincipal();
    },
    "itemPorPage": function () {
      this.page = 1;
      this.getTablaPrincipal();
    },
    "numeroSolicitud": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),
    "tipoSolicitudDevolucion": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),
    "estado": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),
    "fechas": debounce (function () {
      if(this.fechas[0] != null && this.fechas[1] != null) {
        this.$refs.menuFechas.save(this.fechas)
        this.page = 1;
        this.getTablaPrincipal();
      }
    }, 500),
    "informacionUbicacionFiltro": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),
    "numeroIngreso": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),
    "numeroDocumento": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),
    "nombrePaciente": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),
    "cama": debounce (function () {
      this.page = 1;
      this.getTablaPrincipal();
    }, 500),

    "informacionAlternativas": function () {
      this.getUbicacionesMedicamentos(); 
    },
    "selectUbicacion": function () {
      this.postCantidades();
    },
    "informacionLotes": function () {
      this.inputTripleAactivar = false;
      this.inputCantidadActivar = false;
    },

    "codigoTripleA": function () {
      this.inputCantidadActivar = false;
    },
    "inputCantidad": function () {
      this.inputUbicacionMotivo = false;
    },
    "informacionUbicacion": function () {
      this.desactivarMotivo = false;
      this.desactivarMotivoDevolucion = false;
    },
    "inputCantidadDevuelta": function () {
      this.desactivarUbicacionDevolucion = false;
    },
  },
});
</script>

<style scoped>
::v-deep .tablaPrincipal table {
  overflow-y: scroll !important;
  max-height: 48vh !important;
  display: block !important;
}
::v-deep .tablaDetalle table {
  overflow-y: scroll !important;
  max-height: 48vh !important;
  display: block !important;
}

::v-deep .tablaPrincipal div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaDetalle div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tablaDetalle div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaPrincipal div table thead tr th {
  padding-left:12px;
  padding-right:12px ;
}
::v-deep .tablaPrincipal div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .v-messages {
  display: none;
}

</style>
